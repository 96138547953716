import React from "react";
import { Button, ButtonProps } from "./ui/button";
import { Loader2 } from "lucide-react";
import { Link, useLocation, useNavigation } from "@remix-run/react";

interface NavigationButtonProps extends ButtonProps {
  href: string;
  children: React.ReactNode;
}

/**
 * `LinkButton` is a React functional component that renders a button wrapped in a link.
 * It uses the `useNavigation` hook to access the current navigation state.
 * The button is disabled and shows a loading spinner if the current path matches the `href` prop.
 *
 * @param {NavigationButtonProps} props - The props for the button component.
 * @param {string} props.href - The URL to navigate to when the button is clicked.
 * @returns {JSX.Element} The rendered link button component.
 */
const LinkButton: React.FC<NavigationButtonProps> = ({
  href,
  children,
  ...props
}) => {
  const navigation = useNavigation();
  const location = useLocation();
  const relativePath = navigation.location?.pathname?.slice(
    location.pathname.length + 1,
  );

  return (
    <Link to={href}>
      <Button {...props} disabled={navigation.location?.pathname === href}>
        {children}
        {(navigation.location?.pathname === href || relativePath === href) && (
          <Loader2 className="ml-2 h-4 w-4 animate-spin float-right" />
        )}
      </Button>
    </Link>
  );
};

export default LinkButton;
